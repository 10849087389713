import React, { FC, useState, useEffect, useContext, createContext, useCallback } from "react";
import { useAuth } from "../auth/AuthContext";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import { MeepOrderRepository } from "../../../Infrastructure/repositories/api/OrderRepository";
import { KioskRepository } from "../../../Infrastructure/repositories/api/KioskRepository";

interface IKioskContext {
    kiosk: Kiosk | null
}

const Context = createContext<IKioskContext>({} as IKioskContext)

//container
const kioskRepository = KioskRepository();
const orderRepository = MeepOrderRepository();

export const KioskProvider: FC = ({ children }) => {
    const { isAuth } = useAuth()

    const [kiosk, setKiosk] = useState<Kiosk | null>(null)


    useEffect(() => {
        if (kiosk && isAuth) {
            try {
                orderRepository.syncOrdersAsync(kiosk?.localId)
            } catch (error) {
                console.error("CATCH SYNC PENDENT ORDERS", error);
            }
        }
    }, [kiosk, isAuth])


    const interceptorKiosk = useCallback((kiosk: Kiosk | null) => {
        if (kiosk?.localId.toUpperCase() === "5650DE25-2E2A-0EAB-AC51-B5F554BC2908".toUpperCase()) {
            return {
                ...kiosk,
                enableInstallment: true,
            }
        }
        if (kiosk?.localId.toUpperCase() === "24c93427-8517-44fb-866d-d4f13662feb7".toUpperCase()) {
            return {
                ...kiosk,
                enableInstallment: true,
            }
        }
        if (kiosk?.localId.toUpperCase() === "f917eaa8-0f80-23e4-e331-f7bb45b185cc".toUpperCase()) {
            return {
                ...kiosk,
                enableCpfRegister: true,
            }
        }
        if (kiosk?.localId.toUpperCase() === "f536a9b8-e985-b340-8204-77d82f8d0ed1".toUpperCase()) {//beco do espeto
            return {
                ...kiosk,
                oldPrinter: true,
            }
        }
        
        return kiosk;

    }, [])

    useEffect(() => {
        if (isAuth) {
            kioskRepository.syncAsync().then((Kiosk) => {
                console.log("sync Kiosk on Context");
                setKiosk(interceptorKiosk(Kiosk))
            }, (erro) => {
                console.log(erro);
            })
        }
    }, [interceptorKiosk, isAuth])


    useEffect(() => {
        kioskRepository.getAsync().then((kiosk) => {
            setKiosk(interceptorKiosk(kiosk));
        })
    }, [interceptorKiosk])


    return (
        <Context.Provider value={{ kiosk }}>
            {children}
        </Context.Provider>
    )
}

export const KioskConsumer = Context.Consumer

export const useKiosk = () => {
    return useContext(Context)
}
